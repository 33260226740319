<script setup lang="ts">
type Props = {
    icon?: string
    iconColor: string
    iconBgColor: string
    label?: string
    value?: string | number
    unit?: string
    description?: string
}

const props = withDefaults(defineProps<Props>(), {})

const descriptionVisble = ref<boolean>(false)
function toggle() {
    descriptionVisble.value = !descriptionVisble.value
}

const computedValue = computed(() =>
    props.value === 'string' ? props.value : (props.value as Number).toFixed(0),
)
</script>

<template>
    <div class="display-tile">
        <Icon
            fluid
            @click="toggle"
            class="display-tile__options"
            :name="descriptionVisble ? 'close-32x' : 'more-18x'"
        />
        <Modal v-model:open="descriptionVisble">
            <div class="display-tile__description">
                <Icon
                    v-if="icon"
                    :name="icon"
                    :circle="iconBgColor"
                    class="display-tile__description-icon"
                    :auto-color="['stroke', 'fill']"
                />
                <RichText class="rich-text--compact">
                    <p class="small">{{ $t('displayTile.info') }}</p>
                    <h1>{{ label }}</h1>
                    <p v-html="description" />
                </RichText>
            </div>
        </Modal>
        <span class="display-tile__label">
            <Icon
                v-if="icon"
                :name="icon"
            />
            <span>{{ label }}</span>
        </span>
        <span class="display-tile__value-display">
            <span class="display-tile__value">{{ computedValue }}</span>
            <span class="display-tile__unit">{{ unit }}</span>
        </span>
    </div>
</template>

<style lang="scss">
.display-tile {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px;
    position: relative;

    border-radius: 3px;
    background: rgba(247, 247, 247, 0.6);

    &__label {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        align-content: center;
        gap: 7px;
    }

    &__value-display {
        display: inline-flex;
        gap: 6px;
        place-items: flex-end;
    }

    &__value {
        font: v(font-copy);
        font-size: 26px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: -0.52px;
    }

    &__unit {
        color: v(color-text);
        font: v(font-copy--small);
        font-size: 12px;
        line-height: 17px; /* 141.667% */
    }

    &__description {
        min-height: 100%;
        padding: 70px 24px 36px;

        &-icon {
            padding: 26px;
            margin-bottom: 30px;
            color: v(color-text);

            svg {
                width: 28px;
                height: 28px;
            }
        }
    }

    &__options {
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 18px;
        height: 18px;
    }
}
</style>
