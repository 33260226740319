<script setup lang="ts"></script>

<template>
    <div class="tile-grid">
        <slot />
    </div>
</template>

<style lang="scss">
.tile-grid {
    display: grid;
    gap: 18px;
    grid-template-columns: 1fr;

    @include mq-from(xs) {
        grid-template-columns: 1fr 1fr;
    }
}
</style>
